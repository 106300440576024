// ----------------------------------------------
// Efecto de barra al hacer hover sobre un enlace
// ----------------------------------------------
@mixin making_text($name, $color)
  .text-hover-#{$name}
    color: $color
    position: relative
    text-decoration: none
    transition: 0.5s
    &::after
      position: absolute
      content: ""
      width: 80%
      height: 2px
      top: 100%
      left: 12px
      background: darken($color, 10%)
      transition: transform 0.5s
      transform: scaleX(0)
      transform-origin: right
    &:hover, &:focus
      color: darken($color, 10%)
      text-decoration: none
      &::after
        transform: scaleX(1)
        transform-origin: left

// --------------------------------------------------------------------------------------
// Genera o sobreescribe los helpers de colores de Bootstrap 5 con un color personalizado
// --------------------------------------------------------------------------------------
@mixin make_bs_helpers($name, $color, $text)
  .text-#{$name}
    color: $color !important
  .bg-#{$name}
    background-color: $color !important
  .border-#{$name}
    border-color: $color !important
  .btn-#{$name}
    color: $text !important
    background-color: $color !important
    border-color: $color !important
    &:hover
      background-color: darken($color, 5%) !important
      border-color: darken($color, 5%) !important
  .btn-outline-#{$name}
    color: $color !important
    border-color: $color !important
    &:hover, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active
      background-color: $color !important
      border-color: $color !important
      color: $text !important
  .badge-#{$name}
    background-color: $color !important