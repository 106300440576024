body
  font-family: $main-font
  font-weight: 400
  background: $white

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6
  font-family: $main-font
  font-weight: 700

a:hover
  text-decoration: none


::-webkit-input-placeholder
  font-family: $main-font
  font-weight: 400

::-moz-placeholder
  font-family: $main-font
  font-weight: 400

:-ms-input-placeholder
  font-family: $main-font
  font-weight: 400

:-moz-placeholder
  font-family: $main-font
  font-weight: 400