// #main-header

.header-banner
  background-color: $white

.header-banner-img
  max-width: 400px
  height: auto
  width: auto

.header-cover
  width: 100%
  height: 100px
  background-image: url("/images/bg.png")
  background-size: cover
  background-position: center 43vw
  @media (min-width: 670px)
    background-position: center 41vw
  @media (min-width: 800px)
    height: 130px
  @media (min-width: 992px)
    height: 150px
  @media (min-width: 1200px)
    height: 175px
  @media (min-width: 1440px)
    background-position: center 40vw
  @media (min-width: 1600px)
    background-position: center 39vw
