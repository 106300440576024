#loader
  align-content: center
  align-items: center
  background-color: rgba(255, 255, 255, .6)
  display: flex
  height: 100vh
  justify-content: center
  left: 0
  position: fixed
  top: 0
  width: 100%
  z-index: 1200
