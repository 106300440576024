// Navbar
#navbar
  // --------------------------------
  // Container de la navbar principal
  // --------------------------------
  .navbar-brand
    img
      // ------------------
      // Logo de la empresa
      // ------------------
      width: auto
      max-width: 450px
      height: auto
  .navbar-toggler
    // --------------------------
    // Botón de mostrar el offset
    // --------------------------
    border-color: $white
    &:focus
      box-shadow: 0 0 0 2px

.main-header-nav
  // ----------------
  // Navbar principal
  // ----------------
  display: flex
  margin-bottom: 0
  flex-direction: column
  padding-left: 0
  list-style: none
  @media (min-width: 992px)
    display: flex
    margin-bottom: 0
    flex-direction: row
  .menu-item
    // ------------------
    // Ítems de la navbar
    // ------------------
    white-space: nowrap
    &.current-menu-item
      a
        background-color: $secondary
        color: $grey200
    a, router-link
      display: block
      padding: 1rem
      text-decoration: none
      text-transform: uppercase
      font-family: $main-font
      font-weight: 500
      background-color: $primary
      color: $white
      &:hover, &:focus
        background-color: $secondary
  ul
    list-style: none
    padding-left: 0
    margin-left: 0
  .icon.icon-angle-down
    margin-left: 10px
    @media (max-width: 991px)
      display: none
  li
    .sub-menu
      @media (min-width: 992px)
        display: none
        position: absolute
        min-width: 240px
        z-index: 99
    .sub-menu
      @media (max-width: 992px)
        padding-left: 1rem

.main-header-nav li:hover > .sub-menu
  display: block

.main-header-nav li .sub-menu li
  position: relative

.main-header-nav li ul li:hover .selected-menu
  background-color: $secondary
  color: $grey200

.main-header-nav li ul li:hover > .sub-menu
  right: -241px
  top: 0px

.main-header-nav li ul li .sub-menu
  @media (min-width: 992px)
    max-width: 240px
  li
    @media (min-width: 992px)
      white-space: initial

