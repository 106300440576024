.landing
  // ------------
  // Landing page
  // ------------
  box-sizing: border-box
  background-color: $white
  &-inner-pages
    // ----------------
    // Páginas internas
    // ----------------
    background-color: rgba(0, 0, 0, .3)
    background-blend-mode: multiply
  &-row
    padding-top: 40vh
  &-img-voluntad
    // -----------------------------------------------------
    // Imagen de "Un gesto de amor" de las cajas del landing
    // -----------------------------------------------------
    max-width: 300px
    width: 100%
    height: auto
  &-card-header
    // -----------------
    // Cajas del landing
    // -----------------
    @media (min-width: 992px)
      min-height: calc(180px + 0.3vw)
    @media (min-width: 1200px)
      min-height: calc(200px + 0.3vw)
  &-titles
    // --------------------------------
    // Títulos de las cajas del landing
    // --------------------------------
    color: $white
    font-size: calc(1.26rem + 0.12vw)
    font-weight: normal
    line-height: 1.2
    margin-bottom: 0
    margin-top: 0
    padding-top: .5rem
    text-align: center
    @media (min-width: 992px) and (max-width: 1199px)
      font-size: calc(1.1rem + 0.12vw)