.font-12
  font-size: 12px

.font-14
  font-size: 14px

.font-16
  font-size: 16px

.font-18
  font-size: 18px

.font-20
  font-size: 20px

.font-22
  font-size: 22px

.font-24
  font-size: 24px

.font-open
  font-family: 'Open Sans', sans-serif


@include make_bs_helpers("primary", $primary, $white)
@include make_bs_helpers("primary-light", $primary300, $white)
@include make_bs_helpers("primary-dark", $primary700, $white)
@include make_bs_helpers("secondary", $secondary, $white)
@include make_bs_helpers("secondary-light", $secondary300, $white)
@include make_bs_helpers("secondary-dark", $secondary700, $white)
@include make_bs_helpers("accent", $accent, $white)
@include make_bs_helpers("light", $white, $black)
@include make_bs_helpers("danger", #e3342f, $white)
@include make_bs_helpers("whatsapp", #00BB2D, $white)

@include making_text('primary', $primary)


// Fixes
.btn
  font-weight: 700!important

.card-header
  background-color: rgba(0, 0, 0, .12)

.fa-trash
  width: 16px

.form-label
  font-weight: 700

.img-icon
  height: 45px
  width: auto

.img-list
  width: 18px
  height: auto
  margin-right: 5px

.site-main
  padding: 25px
  background-color: rgba(255, 255, 255, .8)
  min-height: 60vh
  border-radius: 5px
  box-shadow: 0px 0px 10px rgba(0, 0, 0, .7)

// Botón cancelar para la modal del eliminar registro
.btn-cancel-to-swal
  color: $primary700 !important
  border: 1px solid $primary700 !important
  background-color: transparent !important
  &:hover, &:focus
    background-color: $primary700 !important
    border-color: $primary700 !important
    color: $white !important

// Botón cancelar para la modal del eliminar registro
.page-item.active .page-link
  background-color: $primary
  border-color: $primary
.page-link
  color: $primary

.pull-left
  float: left

.pull-right
  float: right

// Vue-tables
.VueTables.VueTables--client
  .VuePagination.row.col-md-12 
    overflow-x: auto

.living-donor-section
  background: $grey200
  padding: 15px
  border-radius: 0.25rem
  border: 1px solid rgba(0, 0, 0, 0.125)

// Color de los accordeon cuando están abiertos
.accordion
  --bs-accordion-active-bg: var(--bs-gray-200)
.accordion-button:not(.collapsed)::after
  background-image: var(--bs-accordion-btn-icon)

// X-Small             None  < 576px
// Small                 sm  ≥ 576px
// Medium                md  ≥ 768px
// Large                 lg  ≥ 992px
// Extra large           xl  ≥ 1200px
// Extra extra large    xxl ≥ 1400px
